.chat-window {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 400px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-header {
  background-color: #0084ff;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.chat-messages {
  padding: 4px;
  overflow-y: auto;
  flex-grow: 1;
}

.chat-input-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f1f1f1;
}

.chat-input {
  border: none;
  flex-grow: 1;
  margin-right: 10px;
}

.send-button {
  color: #0084ff;
  cursor: pointer;
}
