
.floating-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0C9;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  font-size: 24px;
  line-height: 60px;
  cursor: pointer;
  z-index: 1000;
}
